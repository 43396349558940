<template>
  <l-default>
    <OTitle>Privacy Policy</OTitle>
    <CShareThisFollow/>
    <CLegal>
      <div id="privacy"></div>
    </CLegal>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import CLegal from '../../components/CLegal'
import CShareThisFollow from '../../components/CShareThisFollow'

export default {
  name: 'PagePrivacy',
  components: { CLegal, OTitle, LDefault, CShareThisFollow },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Privacy',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Privacy'
      },
      {
        name: 'keywords',
        content: 'myphonerobot privacy'
      }
    ]
  },
  mounted () {
    fetch('/static/privacy.html')
      .then(response => response.text())
      .then(template => {
        document.getElementById('privacy').innerHTML = template
      })
  }
}
</script>
